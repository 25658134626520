import { Footer } from '@/routes/account/components/legal-footer';
import clsx from 'clsx';
import * as React from 'react';

type DashboardViewProps = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  footnotes?: JSX.Element;
  theme?: 'light' | 'dark';
};

export function DashboardView(props: DashboardViewProps) {
  const { fullWidth = false, theme = 'light' } = props;
  return (
    <main
      className={clsx(
        'flex-1 flex flex-col p-4',
        {
          'mx-auto w-full max-w-screen-lg': !fullWidth,
        },
        props.className,
      )}
    >
      <div className="flex-1">{props.children}</div>
      <Footer footnotes={props.footnotes} theme={theme} />
    </main>
  );
}
