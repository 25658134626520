import clsx from 'clsx';

export function Footer(props: { theme?: 'light' | 'dark'; footnotes?: React.ReactNode }) {
  return (
    <footer
      className={clsx(
        'flex flex-col items-center gap-4 pt-10 pb-4 font-light text-sm',
        props.theme === 'light' ? ' text-grey-dark' : 'bg-black text-white-background',
      )}
    >
      <div className={clsx('text-sm w-full max-w-screen-lg flex flex-col', 'lg:bottom-0 lg:inset-x-0')}>
        <div className="py-6 flex border-t-1 border-black-lightest border-solid">
          <p className="inline text-left font-light md:block">
            HM Bradley, Inc. (HMBradley) is a financial technology company, not a bank. Deposit accounts provided by New
            York Community Bank, a division of Flagstar Bank, N.A., Member FDIC. The HMBradley Credit Card is issued by
            MRV Banks pursuant to a license from Mastercard and is subject to approval.
          </p>
        </div>
        {props.footnotes && <div className="flex gap-2 flex-col">{props.footnotes}</div>}
      </div>
    </footer>
  );
}
